@use '../lib';

@keyframes response{
  from {opacity :1;}
  to {opacity :0;}
}

@-webkit-keyframes response{
  from {opacity :1;}
  to {opacity :0;}
}

.subscription{
  width: 100%;

  &__holder{
    @include lib.align();
  }

  &__box{
    max-width: 550px;
    background: lib.color('text');
    margin: 0 auto;
    border-radius: 0.625rem;
    padding: 3.25rem 2.5rem;

    @include lib.mq($from: 'tablet') {
      padding: 6.25rem 4.5rem;
    }
  }

  &__head{
    @include lib.heading10(false, lib.color('secondaryTint1'));
    margin-bottom: 1rem;
  }

  &__section{
    margin-bottom: 3.125rem;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &__error{
    @include lib.bodySansMedium(false, lib.color('primary'));
    margin-top: 0.625rem;
  }

  &__select{
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 0;
    border-bottom: 1px solid rgba(lib.color('secondaryTint1'), 0.1);
    cursor: pointer;
  }

  &__captcha{
    margin-top: 2rem;
    height: auto;
    @include lib.mq($until: 'tablet') {

      & > div:first-child {
        height: 52px;
      }

      & > div > div:first-child {
        transform:scale(0.67) !important;
        -webkit-transform:scale(0.67) !important;
        transform-origin:0 0 !important;
        -webkit-transform-origin:0 0 !important;
      }
    }    
  }

  &__item{
    @include lib.bodyText(false, lib.color('secondaryTint1'));
  }

  &__subscribeCta{
    min-width: auto;
    margin-top: 2.5rem;
  }

  &__status{
    @include lib.heading6();
    color: lib.color('secondary6');
    text-align: center;
    margin: 1rem 0;

    animation:response 0.5s 1;
    -webkit-animation:response 0.5s 1;
    animation-fill-mode: forwards;

    animation-delay:4s;
    -webkit-animation-delay:4s; /* Safari and Chrome */
    -webkit-animation-fill-mode: forwards;

  }

}