@use '../lib';

.hero{

  width: 100%;
  position: relative;
  padding-bottom: 1px; // to avoid margin collapse with bottom widget
  border-bottom: 2px solid #5e85d900;


  &__wrapper{
    max-width: 1110px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    // margin-bottom: 4.5rem;
    margin-bottom: 1.5rem;
    @include lib.defaultSpace(left);

    @include lib.mq($from: "projector") {
      max-width: 1300px;
    }

    &--quotes{
      @include lib.mq($from: "laptop") {
        margin-bottom: 5rem;
      }
    }
  }

  &__headerTextLabel{
    @include lib.bodyMetaMini();
    display: block;
    margin-bottom: 1.5rem;
    @include lib.mq($from: 'laptop') {
      margin-bottom: 2rem;
    }
    @include lib.mq($from: 'monitor') {
      display: none;
    }
  }

  &__banner{
    transition: all 500ms;
    background-image: url('#{lib.$assetPath}/images/hero.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 47%;
    width: 100%;
    background-position: 50% 0;
    
    @include lib.mq($from: "laptop") {
      height: 31.25rem;
      background-size: cover;
      padding: inherit;
    }

    @include lib.mq($from: "desktop") {
      height: 40.625rem;
      width: calc(100% + 25%);
    }

    @include lib.mq($from: "projector") {
      height: 45rem;
      width: calc(100% + 40%);
    }

    &--about{
      background-image: url('#{lib.$assetPath}/images/about.jpg');
    }

    &--members{
      background-image: url('#{lib.$assetPath}/images/members.jpg');
    }

    &--meetings{
      background-image: url('#{lib.$assetPath}/images/meetings.jpg');
    }

    &--management{
      background-image: url('#{lib.$assetPath}/images/management.jpg');
    }

    &--sustain{
      background-image: url('#{lib.$assetPath}/images/sustain.jpg');
    }

  }  

  &__quotes{    
    padding: 1.25rem;
    background: lib.color('secondary2');
    border-radius: 5px;
    width: 80%;

    @include lib.mq($from: "laptop") {         
      position: absolute;
      bottom: -4rem;
      left: 6%;
      padding: 2.5rem;      
      max-width: 60rem;
    }

    @include lib.mq($from: "desktop") {
      padding: 3.125rem 3.125rem 2.5rem;
      width: 100%;
    }

    &--mobile{
      margin: -3rem auto 0;
      @include lib.mq($from: "laptop") { 
        display: none
      }
    }

    &--tablet{
      @include lib.mq($until: "laptop") { 
        display: none
      }
    }

  }

  &__text{    
    @include lib.heading9(lib.color('secondaryTint1'));

    &::before,
    &::after{
      content: '"';
    }
  }

  &__author{
    @include lib.bodyMetaMini(false, rgba(lib.color('secondaryTint1'), 0.5));
    display: block;
    margin-top: 0.625rem;
    @include lib.mq($from: "tablet") {      
      margin-top: 1rem;
    }
  }

  &::before{
    @include lib.mq($from: 'monitor') {
      @include lib.bodyMetaMini(false, lib.color('text'));
      content: attr(data-numbers);
      position: absolute;
      top: 50%;
      left: 3rem;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(270deg);
      text-align: center;
      width: 30%;
    }
  }

}