@use '../../lib';

.formOptions {
  text-align: left;

  &__legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    @include lib.bodyMeta();
    margin-bottom: 0.25em;
  }

  &__main{
    visibility: visible; // to remove css warning temporary 
  }

  &__message {
    @include lib.bodyMeta();
    color: lib.color('warning');
    margin-top: 0.5rem;
  }

  &--changeEmail {
    @include lib.mq($from: 'tablet') {
      flex: 1;
    }
  }
}
