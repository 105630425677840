@use '../lib';

.footerVariant {
    background: lib.color("secondary2");
  
    padding: 3.75rem 1.25rem;
  
    @include lib.mq($from: tablet) {
      padding: 5rem 2.5rem;
    }
  
    @include lib.mq($from: laptop) {
      padding: 5rem 3.75rem;
    }
  
    @include lib.mq($from: desktop) {
      padding: 5rem 10rem;
    }
  
    @include lib.mq($from: projector) {
      padding: 5rem 25rem;
    }
    &__main {
      // @include mixin.align();
  
      &--footerGift {
        max-width: 1110px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  
    &__support {
      &:before {
        padding: 0.3rem 1.2rem;
        content: "";
        background: url("#{lib.$assetPath}/images/shared/customerservice.svg")
          no-repeat;
      }
  
      @include lib.bodyText();
  
      margin-bottom: 2.5rem;
      color: lib.color("secondaryTint1");
      border-bottom: 1px solid rgba(lib.color("secondaryTint1"), 0.1);
      padding-bottom: 2.5rem;
  
      &--cleanVariant {
        border: none;
        padding-bottom: 0;
      }
  
      &--campaignArticle {
        padding-bottom: 0;
        border: none;
      }
    }
  
    &__header {
      @include lib.heading4();
      color: lib.color("secondaryTint1");
  
      @include lib.mq($from: "laptop") {
        font-size: 20px;
      }
    }
  
    &__link {
      color: inherit;
      // white-space: nowrap;
    }
  
    &__social {
      display: flex;
      margin-top: 0.75rem;
      justify-content: flex-start;
  
      &--campaignArticle {
        display: none;
      }
    }
  
    &__socialItem {
      &--facebook {
        background: url("#{lib.$assetPath}/images/shared/icn-facebook.svg") no-repeat;
        text-indent: -9999px;
      }
  
      &--instagram {
        background: url("#{lib.$assetPath}/images/shared/icn-instagram.svg") no-repeat;
        text-indent: -9999px;
      }
  
      display: flex;
      color: inherit;
      margin: 0 0.935rem 0 0rem;
      width: 20px;
      height: 20px;
    }
  
    &__navigationWrapper {
      display: flex;
      flex-direction: column;
  
      @include lib.mq($from: "tablet") {
        flex-direction: row;
      }
  
      &--campaignArticle {
        display: none;
      }
    }
  
    &__navigationList {
      margin: 0 0 1.55rem 0;
      @include lib.mq($from: "tablet") {
        margin: 0 3.75rem 0 0;
      }
    }
  
    &__navigation {
      margin: 0.9375rem 0 0;
    }
  
    &__navigationItem {
      display: block;
      padding: 0.685rem 0 0;
      break-inside: avoid;
      @include lib.bodySansMedium();
      color: lib.color("secondaryTint1");
    }
  
    &__disclaimer {
      margin-top: 0.5rem;
  
      @include lib.mq($from: "laptop") {
        margin-top: 2.5rem;
        display: flex;        
        align-items: flex-end;
        justify-content: space-between;        
      }
    }
  
    &__rightContext {
      @include lib.mq($from: "tablet") {
        display: flex;
        flex-direction: column;
      }

      @include lib.mq($from: 'laptop') {
        flex-direction: row;
      }

    }
  
    &__copyright {
      @include lib.bodyMeta(false, lib.color('secondaryTint1'));
      @include lib.mq($from: 'laptop') {
        text-align: right;
      }
    }
  
    &__links {
      @include lib.bodyMeta(false, lib.color('secondaryTint1'));
      display: flex;
      margin-top: 0.3rem;
      justify-content: flex-start;
      flex-wrap: wrap;
  
      @include lib.mq($from: "laptop") {
        margin-top: 0rem;
        flex-direction: row;
      }
    }
  
    &__linksItem {        
      color: inherit;
      // margin-right: 1.5rem;
      // margin-bottom: 1rem;
      margin: 0 1.5rem 1rem 0;
  
      // @include lib.mq($from: "laptop") {
      //   &:last-child {
      //     margin-right: 0;
      //     &:after {
      //       display: none;
      //     }
      //   }
      // }

      @include lib.mq($from: "laptop") {
        margin-bottom: 0;
      }
    }
  
    &__relations {
      @include lib.bodyMeta();
      max-width: 80%;
      margin-top: 1.5rem;
      display: inline-block;
  
      a {
        color: inherit;
        text-decoration: underline;
      }
  
      @include lib.mq($from: "tablet") {
        flex: 1 0 55%;
        margin-top: 0;
        text-align: right;
        align-self: flex-start;
      }
    }
  
    &__brand {
      display: flex;
      margin-top: 3.75rem;
      // margin-bottom: 0.625rem;
      // justify-content: flex-start;
      // align-items: center;
      flex-direction: column;
  
      // transform: scale(0.8);
      // transform-origin: 0% 35%;
  
      @include lib.mq($from: "laptop") {        
        margin-top: 1.5rem;
      }
    }

    &__brandGroup{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      @include lib.mq($from: 'laptop') {
        margin-bottom: 1.25rem;
      }
    }

    &__logo{
      border-right: 1px solid rgba(lib.color('secondaryTint1'), 0.5);
      padding: 0.375rem 1.25rem 0.375rem 0;
      margin-right: 1.25rem;
      width: 120px;

      @include lib.mq($from: 'tablet') {
        width: 140px;
      }
    }
  
    &--pageFooter {
      margin-top: 4rem;
      padding-bottom: 9rem;
      @include lib.mq($from: tablet) {
        padding-bottom: 10rem;
      }
    }

    &__brandText{
      @include lib.heading6();
      color: lib.color('secondaryTint1');    
    }
  
    &--footerGift {
      padding: 3.75rem 1.25rem;
  
      @include lib.mq($from: "tablet") {
        padding: 5rem 2.5rem;
      }
  
      @include lib.mq($from: "laptop") {
        padding: 5rem 3.55rem;
      }
  
      @include lib.mq($from: "desktop") {
        padding: 5rem 0;
      }
    }

    &--spaceTop{
      margin-top: 2.5rem;
      @include lib.mq($from: "tablet") {
        margin-top: 6rem;
      }
    }
  }
  