@use '../../lib';

@keyframes global-loading {
  100% {
    transform: rotate(360deg);
  }
}

.loader{
    position: relative;
    width: 100%;
    height: 100%;

    &__holder{
      &::after{
        content: ' ';
        position: absolute;
        top: 46%;
        left: 50%;
        width: 1.25em;
        height: 1.25em;
        border-color: #e9516400 #e951644d #e9516499 #e95164;
        border-style: solid;
        border-width: 2px;
        border-radius: 50%;
        -webkit-animation: global-loading 1.25s linear infinite;
        animation: global-loading 1.25s linear infinite;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
}