@use '../../lib';

.formInput {
  position: relative;

  &__field {
    width: 100%;
    font-size: lib.px2rem(18px);
    line-height: normal;

    position: relative;
    display: block;
    text-decoration: none;
    margin: 0;

    min-height: 2.875rem;
    padding: 0.625rem 1rem;

    background: #fff;
    border: 1px solid lib.color('inputBorder');
    border-radius: 0.125rem;
    appearance: none;
    box-sizing: border-box;

    white-space: nowrap;
    box-shadow: 0 0 0;
    vertical-align: middle;

    &:active,
    &:focus {
      outline: none;
      border: 1px solid lib.color('focus');
      transition: border 0.6s linear;
    }

    &::-moz-focus-inner {
      border-width: 0;
      border-style: none;
      padding: 0;
    }

    &::placeholder {
      @include lib.bodyText();
      color: lib.color('placeholder');
      line-height: normal;
      opacity: 0.5;
    }

    &:disabled {
      color: rgba($color: #000000, $alpha: 0.5);
    }

    &--subscribe{
      visibility: visible;
    }
  }

  &__password {
    background-repeat: no-repeat;
    width: 24px;
    height: 15px;
    position: absolute;
    top: 1.375rem;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;

    // &--show {
    //   background-image: url('#{lib.$assetPath}/images/shared/password-show.svg');
    // }

    // &--hide {
    //   background-image: url('#{lib$assetPath}/images/shared/password-hide.svg');
    //   height: 23px;
    // }
  }

  // &__lock {
  //   background-repeat: no-repeat;
  //   width: 9px;
  //   height: 14px;
  //   position: absolute;
  //   top: 1.475rem;
  //   transform: translateY(-50%);
  //   right: 1rem;
  //   background-image: url('#{lib.$assetPath}/images/shared/lock.svg');
  // }

  input[type='password'] {
    padding-right: 3rem;
  }
}

