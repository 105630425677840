@use '../lib' as *;


$columns : 12;

.textBlock{
    @include align();

    &__item{
        margin: 0 auto;
        @for $i from 1 through $columns{
            &--#{$i}{
                max-width: wrapperMaxWidth(12);
                @include mq($from: 'tablet') {
                    max-width: wrapperMaxWidth($i);
                }                
            }
        }
        
        // element selector used since the content is generated from CMS

        & p, & span {
            @include bodyText();
        }

        & p {
            margin: 2rem 0;
        }

        & ul {
            padding-left: 1.75rem;
            margin: 1.75rem 0;
        }

        & li{
            @include bodyText();
            margin-bottom: 0.225rem;
            &:last-child{
                margin-bottom: 0;
            }
        }

        & a{
            @include bodyMetaMini(false, color('primary'));
            display: block;
            margin-bottom: 0.625rem;
            &::before{
                content: "";
                background: url("#{$assetPath}/images/shared/icon-link.svg") no-repeat;
                width: 13px;
                height: 10px;
                display: inline-block;
                padding-right: 0.425rem;
            }

            &[download]{
                &::before{
                    background: url("#{$assetPath}/images/shared/icon-download.svg") no-repeat;
                    width: 13px;
                    height: 11px;
                }
            }

            &:last-child{
                margin-bottom: 0;
            }
        }

        & a span,
        & a p{
            @include bodyMetaMini(false, color('primary'));
            margin: 0.625rem 0;
        }
    }

    &--hideImage{
        visibility: visible;
        & img {
            display: none;
        }
    }
    
}