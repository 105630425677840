@use '../../lib';

.checkbox{
  cursor: pointer;
  height: 2em;
  width: 2em;
  display: inline-block;
  border-radius: 1rem;
  border: 1px solid lib.color('inputBorder');
  background: lib.color('smoke');
  transition: border 0.3s ease, background 0.3s ease;
  text-align: center;

  svg {
    fill: transparent;
    top: 0.4rem;
    position: relative;
    transition: fill 0.3s ease;
  }

  &--active {
    background: lib.color('primary');
    border-color: lib.color('primary');
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  // &--inactive {
  //   background: lib.color('text');
  //   border-color: lib.color('text');
  //   color: #fff;

  //   svg {
  //     fill: #fff;
  //   }
  // }
}